@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&display=swap);



body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.sidebar-content a {
  text-transform: capitalize !important;
}

.hONoP .activeClicked li {
  color: red !important;
  background-color: #283e64 !important;

}

.hONoP .activeClicked span {
  color: rgb(252, 252, 252) !important;

}


.left-logo {

  height: auto;
  width: 62px;
}

/* styles for sidebar icons */
/* In your CSS file (e.g., App.css) */
/* @import '~font-awesome/css/font-awesome.min.css'; */

.icon_check {
	color: #00BBB3;
	font-size: medium;
}

.img_logoNew {
	display: flex;
	justify-content: center !important;
	align-items: center !important;
}

.altimaximg {
	background-color: #E8F1F6;
	width: 100%;
}

.logoimg {
	margin: 0px auto;
	display: flex;
	justify-content: center;
	align-items: center;

}




/* .logo-text{ */

.input-color {
	color: #393939;
}

.email-text {
	font-size: 18px;
	margin-bottom: 5px 0;
}

.password-text {
	font-size: 18px;
}

.login {
	background-color: #FFFFFF;
	margin: 35px 0;
	padding: 20px 90px;
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.logins {
	font-family: 'ralewaymedium';
	text-align: center;
	color: #00BBB3;
	font-weight: 700 !important;
	margin-bottom: 30px;
	font-size: 26px;
	margin-top: 30px;
}

.form-group {
	margin-bottom: 15px;
	display: block !important;
}

label {
	display: inline-block;
	max-width: 100%;
	margin-bottom: 5px;
	font-weight: 600;
}

.form-control {
	display: block;
	width: 100%;
	padding: 0.375rem 0.75rem;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #212529;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #ced4da;
	-webkit-appearance: none;
	appearance: none;
	border-radius: 0.25rem;
	transition: border-color .15s ease-in-out;
	box-shadow: .15s ease-in-out;
	font-size: 13px !important;
}



.box {
	height: 48px !important;
	background-color: #E8F1F6;
	/* font-family: 'ralewaysemibold'; */
	font-size: 14px !important;
	border: none;
}

.lab {
	display: inline-block;
	max-width: 100%;
	margin-bottom: 5px;
	font-weight: 600;
}

.forgetpass a {
	font-family: 'ralewaybold';
	color: #00bbb4 !important;
	text-decoration: none;
	font-size: 16px;
}

.submit {
	background-color: #00bbb4 !important;
	border: 0 none;
	color: #fff !important;
	padding: 13px 50px !important;
	text-transform: uppercase !important;
	font-family: 'ralewaybold';
	font-size: 16px !important;
	margin-bottom: 25px !important;
	cursor: pointer;
}

.btn {
	display: inline-block;
	font-weight: 400;
	line-height: 1.5;
	color: #212529;
	text-align: center;
	text-decoration: none;
	vertical-align: middle;
	cursor: pointer;
	-webkit-user-select: none;
	user-select: none;
	background-color: transparent;
	border: 1px solid transparent;
	padding: 0.375rem 0.75rem;
	font-size: 1rem;
	border-radius: 0.25rem;
	transition: color .15s ease-in-out;
	background-color: .15s ease-in-out;
	border-color: .15s ease-in-out;
	box-shadow: .15s ease-in-out;
}


.details {
	line-height: 2.5;

}

.logininfo {
	margin: 110px 0;
	padding: 38px;
	font-family: 'ralewaymedium';
	color: #000002 !important;
}

.icon-tick {
	color: #00bbb4;
}

.icon-space {
	padding-left: 6px;
	font-weight: 500;
	font-size: 15px;
}

.icon-space1 {
	margin-left: 20px;
	font-weight: 500;
	font-size: 15px;
}

.footer {
	background-color: #00BBB4;
	display: flex;
	align-items: center;
	justify-content: center;
}

.list-inline {
	display: flex;
	padding-left: 0;
	list-style: none;
}

.socialicon {
	margin-top: 7px;
}

.footer-img {
	margin: 15px;

}

.footer-para {
	margin: 33px;
	color: #fff;
	text-align: center;

	font-family: 'ralewaysemibold';
	font-size: 15px;
}

.socialicon {
	margin-top: 28px;
	margin-left: 32px;
}

.socialicon li {
	list-style: none;
	display: inline-block;
	margin-right: 15px;
}


.socialicon a {
	display: block;
	width: 33px;
	height: 33px;
	text-align: center;
	line-height: 33px !important;
	border-radius: 50%;
	background-color: white;
	text-decoration: none;
	font-size: 19px;
	color: #00BBB4;
}

.logomm {
	width: 100px;

}

.sizeof_heading {
	font-size: 14px !important;
	font-weight: 500 !important;
}

.Inactive_label {
	background-color: red;
	font-size: 12px;
	font-weight: 700;
	color: white;
	padding: 7px 11px;
	margin-left: 2px;
}


/* Admin page 2 settings */


.sidebar-content a {
	text-transform: capitalize !important;
}

.hONoP .activeClicked>li {
	color: red !important;
	background-color: #283e64 !important;

}

.hONoP .activeClicked span {
	color: white !important;

}

.checkbox_visa {
	display: flex;
}

.form-check-label {
	margin-bottom: 0;
	padding: 2px 14px;
}

.checkmtop {
	margin-left: 15px;
}

.logoimgnav2 {
	margin-Right: 8px;
	height: auto;
	border-Radius: 50%;
	height: 22px;
	width: 22px;


}
.logo_notes {

	height: auto;
	width: 17px;


}
.history_dropdown {
	margin-top: 7px;
}

.text-candidate {
	font-size: 14px;
	font-weight: 700;
}

.candidate-row {
	padding: 0px 0px 0px 21px;
}

.Candidate-dropdown {

	margin: 20px 2px;
}

.Account-report {
	text-align: center;
}

.nav-item.active {
	background-color: red !important;
}

.nav-link.active {
	color: white;
}

@media (max-width:1000px) {
	.candidate-row {
		padding: 0px 15px !important;
	}


}

@media (max-width:920px) {
	.mob-inputreport {
		padding: 8px 0px !important;
	}

	.mob-reportfull {
		display: grid !important;
	}
}


/* @media (min-width:1000px) {
	.Reqhide-desktop{
		display: block;
	}

} */
/* @media (max-width:1600px) {
	.Reqhide-mobview{
		display: block;
	}

} */

.bg-color {
	background-color: #e2e6ee !important;
	padding: 10px 0px 0px 5px;

}

.color-report {
	background-color: white !important;
}

.reqcontent-centre {
	display: flex !important;
	justify-content: center !important;
	align-items: center !important;
}

.button-noneSelected {
	border: 1px solid grey;
	font-size: 13px;
	padding: 5px;
}

.text-assign {
	font-size: 15px;
	font-weight: 500;
	padding: 0px 15px 0px 0px;
}

.text-assign11 {
	font-size: 15px;
	font-weight: 500;
	padding: 7px 15px 0px 0px;
}

/* report page styles */

.report-date {
	display: flex;
	padding: 16px 24px;
}

.report-dateheading {
	margin-right: 10px;
	font-size: 14px;
	font-weight: 600;
	/* margin-left: 10px; */

}

.report-inputdate {
	padding: 5px 8px;
	font-size: 13px;
	margin-right: 20px;
}

.report-search {
	background-color: rgb(86, 187, 226);
	border: 1px solid rgb(73, 183, 226);
	border-radius: 4px;
	font-size: 12px;
	padding: 6px 7px;
	font-weight: 600;
	color: white;
}

.report-color1 {
	text-decoration: none;
	color: white;
	font-weight: 600;
	font-size: 15px;
	background-color: #5b7399;
	padding: 8px 4px 3px 4px;
	margin-right: 6px;
	/* border-radius: 4px; */
}

.report-color1:hover {
	text-decoration: none;
	color: black;
	font-weight: 600;
	font-size: 15px;
	background-color: white;
	padding: 8px 4px 3px 4px;
	margin-right: 6px;
	/* border-radius: 4px; */
}
.report-color1_hover {
	text-decoration: none;
	color: black;
	font-weight: 600;
	font-size: 15px;
	background-color: white;
	padding: 8px 4px 3px 4px;
	margin-right: 6px;
	/* border-radius: 4px; */
}

.report-color1_hover:hover {
	text-decoration: none;
	color: black;
	font-weight: 600;
	font-size: 15px;
	background-color: white;
	padding: 8px 4px 3px 4px;
	margin-right: 6px;
	/* border-radius: 4px; */
}


.justify-content-end1 {
	display: flex !important;
	justify-content: flex-end !important;

}

.changepas-center {
	margin-top: 14px;
	display: grid;
	justify-content: center;
}

/* .text-dec{
	text-decoration: none;
} */
a {
	text-decoration: none;
}

/* styles for icons */

/* .fa-user-cog:before {
    content: "red";
	color: red;
} */
* {
  font-family: "Montserrat", sans-serif;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #ccc;
}

.sidebar-content a {
  text-transform: capitalize !important;
}

.hONoP .activeClicked>li {
  color: red !important;
  background-color: #283e64 !important;

}

.color {
  background-color: #8F9FBA !important;
  padding: 8px !important;
  font-size: 13px !important;
  color: white !important;
  width: 50px;
}

td {
  border-bottom: 1px solid #ddd !important;
  padding: 12px !important;
  text-align: left;
  font-size: 12px !important;
}

.save-btn {
  text-align: end;
  font-size: 12px;
  font-weight: 600;
  color: white;
  background-color: #315189;
  margin-left: 10px;
  /* margin-top: 10px; */


}

.interview_class {
  display: block;
  width: 380px;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out;
  box-shadow: .15s ease-in-out;
  font-size: 13px !important;
}





.save-btn:hover {
  color: white;
  background-color: #315189;
}

@media (max-width:530px) {

  .save-btn {

    margin-top: 10px !important;


  }
}

.save-row {
  padding: 0px 21px;
  margin-top: 19px;
  display: flex;
  align-items: center;

}

.spinner-style {
  /* height: 117%; */
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed !important;
  top: 50%;
  height: 0px !important;
}

.spinner-styletop {
  /* background-color: burlywood; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 0px !important; */
  /* top: 50%; */
}

.hONoP .activeClicked span {
  color: white !important;

}

.imkBTA {
  background: #162031;
  color: #fff;
  height: 100%;
  width: 210px !important;
  min-width: 210px !important;
  text-align: left;
  transition: width, left, right, 0.3s;
  position: relative;
  z-index: 1009;
}


.kgVgLX {

  background: #162031 !important;
  color: #fff !important;
  height: 100%;
  width: 210px !important;
  min-width: 210px !important;
  text-align: left;
  transition: width, left, right, 0.3s;
  position: relative;
  z-index: 1009;


}

.eDStxa .side-icon {
  margin-right: 0px !important;
  width: 30px;
}

.imkBTA.toggled {
  width: 80px !important;
  min-width: 80px !important;
}

.gmeTLe .item-content {


  /* text-overflow: ellipsis; */
  /* white-space: nowrap; */
  text-overflow: initial !important;
  white-space: initial !important;
  font-size: 13px;
}

.eDStxa .item-content {
  text-overflow: initial !important;
  white-space: initial !important;
  font-size: 13px;

}

.eDStxa {
  padding: 8px 1px 8px 3px !important;
}


.gmeTLe {
  padding: 8px 1px 8px 3px !important;
}

.bwesBh .activeClicked>li {
  background: #29374f !important;
  color: #aeb2b7 !important;
}

.ktshyD .activeClicked>li {

  background: #29374f !important;
  color: #aeb2b7 !important;

}

.activeClicked.gmeTLe .side-icon {

  color: blue;

}

.eDStxa {

  color: #aeb2b7 !important;

}

.bwesBh .activeClicked>span {
  background: #29374f !important;
  color: #aeb2b7 !important;
}

.hQSxZz .activeClicked.side-icon {


  color: aqua !important;
}

.gmeTLe .side-icon {
  margin-right: 0px !important;
  width: 30px;
}

.hQSxZz {
  list-style: none;
  margin: 10px 15px;
  color: #aeb2b7 !important;
}

.hQSxZz:hover {
  list-style: none;
  margin: 10px 15px;
  background: #29374f !important;
  color: #aeb2b7 !important;
}

.fa-cogs:before {
  content: "\f085";
  /* color: #FF6C60; */
}

.fRhOkl {

  padding-top: 0px;
}

.fa-envelope:before:hover {
  content: "\f0e0";
  color: #FF6C60 !important;

}

.gmeTLe .side-icon :hover {
  color: #FF6C60 !important;
}

.spinner-style {
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}


.logoimgnav {
  margin-Right: 8px;
  height: auto;
  border-Radius: 50%;
  height: 22px;
  width: 22px;


}

.logonav {
  display: flex;
  border: 1px solid white;
  padding: 4px 0px;
  margin-right: 80px;
}


.nav-font {
  font-size: 11px;
  color: white;
  padding: 3px;
  margin-top: 6px;

}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-button {
  background-color: #162031;
  color: #fff;
  border: none;
  padding: 10px 0px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.changepas-center {
  margin-top: 14px;
  display: grid;
  justify-content: center;
}

.dropdown-icon {
  margin-right: 5px;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #fbf8f8;
  width: 130px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  font-size: small !important;
  z-index: 1;

}

.dropdown-contentnav {
  display: none;
  position: absolute;
  background-color: #fbf8f8;
  width: 130px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  font-size: small !important;
  z-index: 1;

}

.dropdown-contentnav a {
  padding: 10px;
  display: block;
  text-decoration: none;
  color: #333;
  transition: background-color 0.2s;

}

.dropdown-contentnav a:hover {
  background-color: #f2f2f2;
}

.dropdown-content a {
  padding: 10px;
  display: block;
  text-decoration: none;
  color: #333;
  transition: background-color 0.2s;

}

.dropdown-content a:hover {
  background-color: #f2f2f2;
}


.dropdown:hover .dropdown-content,
.dropdown:focus-within .dropdown-content {
  display: block;
}

.not-found-container {
  text-align: center;
  padding: 50px;
  font-family: 'Arial', sans-serif;
}

h1 {
  font-size: 3em;
  margin-bottom: 20px;
  color: #ff5722;
  /* Orange color */
}

p {
  font-size: 1.5em;
  color: #333;
}

.not-found-image {
  max-width: 100%;
  margin-top: 20px;
}

.jAaeFT {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.iCVXfv {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.react-datepicker-wrapper {
  display: flex !important;


}

.clean-text {
  background-color: rgb(86, 187, 226);
  border: 1px solid rgb(73, 183, 226);
  border-radius: 4px;
  font-size: 12px;
  padding: 6px 21px;
  font-weight: 600;
  color: white;
}


.email_content_text {

  height :250px;
}
.dashboard .cards-container {
	margin:25px auto;
	display:grid;
	width:100%;
	grid-template-columns:repeat(3, minmax(300px, 1fr));
	grid-gap:25px;
	grid-template-rows:1fr;
}

.note-style {
    height: 120px !important;
}
.statedrop {
    /* width: 62px; */
    /* padding: 7px; */
    border: 1px solid #6f6f6f;
    border-radius: 4px;
    font-size: 14px;
    height: 36px;
    padding: 0px 7px;
    color: rgb(0, 0, 0);
    width: -webkit-fill-available;
}
.dashboard .card-bg {
	border:1px solid #f2f2f2;
	box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.dashboard .wide {
	grid-column:span 2;
}

.dashboard .c-p {
	cursor:pointer;
}

.assign_addbtn {
    margin-bottom: 5px;
    margin-left: 10px;
    margin-top: 3px;
    font-size: 12px;
    font-weight: 600;
    color: white;
    background-color: #315189;

}

.assign_addbtn:hover {
    color: white;
    background-color: #315189;
}

.dashboard .pane-image {
	min-width:3rem;
	height:3rem;
	margin-right:1rem;
}

.dashboard .card-section {
	padding:0 1.5rem;
	box-sizing: border-box;
}

.dashboard .footer {
	display:flex;
	justify-content:space-between;
	align-items:center;
	padding:1.5rem 0;
	width:80%;
	margin:0 auto;
}

.dashboard .footer-link {
	display:flex;
	align-items:center;
	padding:0;
	color:#333;
}

@media (max-width:1320px) {
	.dashboard .cards-container {
		grid-gap: 15px;
		grid-template-columns: repeat(2, minmax(270px, 1fr));
	}
	.dashboard .wide {
		grid-column:span 1;
	}
	.dashboard .card-section {
		padding:0 0.5rem;
	}
	.dashboard .table-rem {
		display:none !important;
	}
	.dashboard .footer {
		width:90%;
	}
}

@media (max-width:920px) {
	.dashboard .cards-container {
		max-width:500px;
		width:90%;
		font-size: 0.85rem;
		grid-template-columns: repeat(1, minmax(220px, 100%));
	}
	.dashboard .msg-rem {
		display:none !important;
	}
	.dashboard .p-4 {
		padding:1rem !important;
	}

	.dashboard .pane-image {
		min-width:2rem;
		height:2rem;
		margin-right:0.5rem;
	}
	.dashboard .footer-rem, .dashboard .footer-link {
		display:none !important;
	}
	.dashboard .footer .d-flex{
		margin:0 auto;
		text-align: center;
	}
}
@media (max-width:375px) {
	.dashboard .h5 {
		font-size:1rem;
	}
	.dashboard .cards-container {
		margin:10px 0;
	}
}

/* setting as dhashboard */

 /* .fourheading {
    color: white !important;
    background-color: #162031 !important;
} 
.fourheading:hover {
    color: white !important;
    background-color: #283e64 !important;
}  */
/* .fourheading:hover {
    color: white !important;
    background-color: #a8b820 !important;
}  */

/* .hONoP a{
    color: red !important;
} */

.fourheading{
    padding: 0px 8px 0px 0px !important;
    font-size: 14px !important;
}


.input_align {
  
    width: 100%;
    border-radius: 4px !important;
    border: 1px solid rgb(193, 192, 192);
    height: 34px !important;

}
.input_align1 {
  
    width: 100%;
    border-radius: 4px !important;
    border: 1px solid rgb(193, 192, 192);
    height: 60px !important;

}

.nav-font {
    font-size: 11px;
    color: white;
    padding: 3px;
    margin-top: 6px;

}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-button {
    background-color: #162031;
    color: #fff;
    border: none;
    padding: 10px 0px;
    cursor: pointer;
    display: flex;
    align-items: center;
}
.changepas-center {
	margin-top: 14px;
	display: grid;
	justify-content: center;
}
.dropdown-icon {
    margin-right: 5px;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #fbf8f8;
    width: 130px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    font-size: small !important;
    z-index: 1;
    
}
.dropdown-contentnav {
    display: none;
    position: absolute;
    background-color: #fbf8f8;
    width: 130px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    font-size: small !important;
    z-index: 1;
    
}
.dropdown-contentnav a {
    padding: 10px;
    display: block;
    text-decoration: none;
    color: #333;
    transition: background-color 0.2s;
   
}

.dropdown-contentnav a:hover {
    background-color: #f2f2f2;
}
.dropdown-content a {
    padding: 10px;
    display: block;
    text-decoration: none;
    color: #333;
    transition: background-color 0.2s;
   
}

.dropdown-content a:hover {
    background-color: #f2f2f2;
}


.dropdown:hover .dropdown-content,
.dropdown:focus-within .dropdown-content {
    display: block;
}

.logonav {
    display: flex;
    border: 1px solid white;
    padding: 4px 0px;
    margin-right: 80px;
}

.logoimgnav {
    margin-Right: 8px;

    height: auto;
    border-Radius: 50%;
    height: 35px;
    width: 35px;
}

/* dashboard as setting page */
.profile-tilte {
    font-size: 24px;
    font-weight: 400;
    background-color: #f2f4f9;
    border-bottom: 2px solid #ddd;
    padding: 15px;

}

/* .border-box {
    border: 2px solid black;
    padding: 20px;
    border-radius: 10px;
} */
.form-group {
    display: flex;
}

.form-textSetting {
    font-size: 13px;
    font-weight: 500;
    display: flex !important;
}

.inputbox-height {

    height: 37px;
}

.required {
    color: red !important;
}

.btn-setting {
    background-color: #315189;
    font-size: 7px;
    color: white;
    font-weight: 600;
    border: 1px solid blueviolet;
    
    margin-top: -44px;
}

.btn-setting:hover {
    color: white;
}

/* .logoimg-setting {
    border-Radius: 50%;
    height: 88px;
    width: 128px;
    margin-top: -27px;
} */

.Editprofile {
    background-color: #e2e6ee;
    font-size: 16px;
    padding: 12px;
    border-bottom: 2px solid #ddd;
    font-weight: 400;

}
.borderbox{
    border: 2px solid #ddd;
    margin: 10px 3px;
}
.background-color {
    width: 100%;
   
    background-color:#f2f4f9;
}
/* .button_top{
    margin-top: 20px;
} */
@media (max-width: 1000px) {
    .form-group {
        display: grid;
    }
    .mob-overflow{
        overflow: auto !important;
    }
    .form-textSetting {
      
        display:grid !important;
    }
    .OrganView {
        display: grid !important;
       
    }

}
.yellow-status{
    background-color: rgb(208, 208, 16);
	font-size: 12px;
	font-weight: 700;
	color: white;
	padding: 6px 10px;
	margin-left: -5px;
}
.green-status {
    background-color: #82af6f;
	font-size: 12px;
	font-weight: 700;
	color: white;
	padding: 6px 16px;
	margin-left: -4px;
  }
  
  .red-status {
    background-color:rgb(198, 77, 77);
	font-size: 12px;
	font-weight: 700;
	color: white;
	padding: 6px 10px;
	margin-left: -6px;
  }
 


/* profile as Organization management */
.Orvalidation_form{
    background-color:#e2e6ee ;
    display: flex;
    justify-content: space-between;
    padding: 0px 8px;
}
.Organ_addbtn{
  text-align: end;
    font-size: 12px;
    font-weight: 600;
    color: white;
    margin: 10px;
   
    background-color: #315189;
   
}
.Organ_addbtn:hover{
    color: white; 
    background-color: #315189;
}
.Orpage_box{
    border: 2px solid #ddd;
    margin: 15px 10px;
    border-radius: 5px;

}
.icon:hover {
    color: red;
  }

  .sidebar-content a {
	text-transform:capitalize !important;
}
.hONoP .activeClicked >li {
  color: red !important;
  background-color: #283e64 !important;
  
}

.hONoP .activeClicked span {
  color:white !important;
  
}
.end-navContent{
    justify-content: flex-end !important;
    margin-right: 33px;
}
/* 
@media (max-width: 530px){
.list_organ {
    font-size: 10px!important;
}
.Organ_addbtn {
    font-size: 8px;
}
} */
.note-style{
    height: 120px !important;
}

/* Add these classes to your CSS file */
.active {
    background-color: green !important;
  }
  
  .inactive {
    background-color: red !important;
  }
  
  .pending {
    background-color: yellow !important;
  }
  
@media (max-width:530px) {


	.Organ_addbtn {
		font-size: 7px;

	}

	.list_organ {
		font-size: 10px !important;

	}

	.Organ_dropdown1 {
		display: grid !important;
		padding: 0px 21px !important;
		margin-top: 19px !important;

	}

	.Organ_dropdown {
		padding-top: 12px !important;

	}

	.close-icon-container {
		position: relative;
		/* top: 74%; */
		/* right: 16px; */
		padding: 5px;
		cursor: pointer;
		top: -150px !important;
		left: 30px;
		text-align: end;
		/* display: flex; */
		font-size: 19px;
	}

}


.modal-header {
	background-color: #3d609a !important;
	color: white !important;
	font-size: 16px;
}

.modal-title {
	margin-bottom: 0;
	line-height: var(--bs-modal-title-line-height);
	font-size: 17px;
}

/*  */
/* .tabledata{
	margin-top: 10px !important;
} */
.tabledata:hover {
	background-color: rgb(232, 231, 231);
}

.list_organ {
	font-size: 16px;
	font-weight: 400;
	padding: 12px;

}

#dropdown {
	width: 62px;
	/* padding: 7px; */
	border: 1px solid #6f6f6f;
	border-radius: 4px;
	font-size: 14px;
	height: 31px;
	padding: 0px 7px;
	color: rgb(171, 169, 169);
}

.Organ_dropdown {
	display: flex;

}

.search_width {
	width: 167px !important;

}

.recordpage {
	font-size: 13px;
	padding: 0px 5px;

}

.recordpage {
	font-size: 14px;
	padding: 4px 5px;

}

.Organ_dropdown1 {
	display: flex;
	justify-content: space-between;
	padding: 0px 21px;
	margin-top: 19px;
}





.Organ_dropdown2 {
	display: flex;
	margin-top: 19px;
}

/* table */
table {
	border-collapse: collapse;
	width: 100%;
}

td {
	border-bottom: 1px solid #ddd;
	padding: 16px;
	text-align: left;
	font-size: 13px;
}

.color {
	background-color: #8f9fba;
	padding: 8px;
	font-size: 13px;
	color: white;
}

.table-colorOrgan {
	border: 1px solid #ddd;
	margin: 20px;
}

.tr_padd {
	padding: 10px;
}


.Active_label {
	background-color: #82af6f;
	font-size: 12px;
	font-weight: 700;
	color: white;
	padding: 6px 16px;
	margin-left: 2px;
}

.padding_sort {
	margin: 6px;
}

.dropdown_alert {
	border: 1px solid rgb(187, 186, 186);
	padding: 1px 8px;
	background-color: #dddde0;
	color: #8f9fba;
	margin: 0px 20px;
}

.Previous_organ {
	display: flex;
	justify-content: space-between;
	padding: 0px 12px 29px 20px;
}
.Previous_save {
	display: flex;
	justify-content: flex-end;
	width: 80%;
	
}

.Next_button {
	border: 1px solid rgb(232, 232, 232);
	background-color: rgb(246, 242, 242);
	font-size: 14px;
	border-radius: 3px;
	margin-right: 4px;
}

.Next_button:hover {
	background-color: #edf3fe;
}

.no1_button_selected {
	border: 1px solid rgb(171, 169, 169);
	background-color: #c3c4c7;
	font-size: 14px;
	border-radius: 3px;
	margin-right: 3px;


}
.no1_button_unselected {
	border: 1px solid rgb(104 96 96);
	background-color: #ffffff;
	font-size: 14px;
	border-radius: 3px;
	margin-right: 3px;


}


/* sub organization */
.Organization_sub {
	margin: 20px 10px;
}

.btn_organization {
	background-color: #315189;
	font-size: 9px;
	color: white;
	font-weight: 600;
	border: 1px solid blueviolet;


}

.arrowkey {
	padding: 0px 5px;
}













/*  */

.OrganView {
	display: flex;
	font-size: 14px;
	font-weight: 600;
	margin: 15px;

}




.sidebar-content a {
	text-transform: capitalize !important;
}

.hONoP .activeClicked>li {
	color: red !important;
	background-color: #283e64 !important;

}

.hONoP .activeClicked span {
	color: white !important;

}


.reqnote_time {
	font-weight: 300;
	text-align: end;
	display: flex;
	justify-content: end;
	align-items: end;
	margin-top: 7px;
}

.req_notesview {
	/* display: flex; */
	font-size: 14px;
	font-weight: 600;
	margin: 27px;
	border: 0.5px solid #bbb6b6;
	padding: 10px;
	font-style: italic;
}

.close-icon-container {
	position: relative;
	/* top: 74%; */
	/* right: 16px; */
	padding: 5px;
	cursor: pointer;
	top: -80px;
	left: 22px;
	text-align: end;
	/* display: flex; */
	font-size: 19px;
	margin-bottom: -21px;
}


.cursor_notallowed {
	cursor: not-allowed !important;
}

.css-1jqq78o-placeholder {

	font-size: 13px !important;
}

.notes_scroll {
	height: 340px;
	overflow-y: scroll;
}

@media (max-width:530px) {


	.Organ_addbtn {
		font-size: 7px;

	}

	.list_organ {
		font-size: 10px !important;

	}

	.Organ_dropdown1 {
		display: grid !important;
		padding: 0px 21px !important;
		margin-top: 19px !important;

	}

	.Organ_dropdown {
		padding-top: 12px !important;

	}

}

/*  */
/* .tabledata{
	margin-top: 10px !important;
} */
.tabledata:hover {
	background-color: rgb(232, 231, 231);
}

.list_organ {
	font-size: 16px;
	font-weight: 400;
	padding: 12px;
	
}

#dropdown {
	width: 62px;
	/* padding: 7px; */
	border: 1px solid #6f6f6f;
	border-radius: 4px;
	font-size: 14px;
	height: 31px;
	padding: 0px 7px;
	color: rgb(171, 169, 169);
}

.Organ_dropdown {
	display: flex;

}

.search_width {
	width: 167px !important;
	
}

.recordpage {
	font-size: 13px;
	padding: 0px 5px;
	
}

.recordpage {
	font-size: 14px;
	padding: 4px 5px;

}

.Organ_dropdown1 {
	display: flex;
	justify-content: space-between;
	padding: 0px 21px;
	margin-top: 19px;
}





.Organ_dropdown2 {
	display: flex;
	margin-top: 19px;
}

/* table */
table {
	border-collapse: collapse;
	width: 100%;
}

td {
	border-bottom: 1px solid #ddd;
	padding: 16px;
	text-align: left;
	font-size: 13px;
}

.color {
	background-color: #8f9fba;
	padding: 8px;
	font-size: 13px;
	color: white;
}

.table-colorOrgan {
	border: 1px solid #ddd;
	margin: 20px;
}

.tr_padd {
	padding: 10px;
}

 
.Active_label {
	background-color: #82af6f;
	font-size: 12px;
	font-weight: 700;
	color: white;
	padding: 6px 16px;
	margin-left: 2px;
}

.padding_sort {
	margin: 6px;
	cursor: pointer;
}

.dropdown_alert {
	border: 1px solid rgb(187, 186, 186);
	padding: 1px 8px;
	background-color: #dddde0;
	color: #8f9fba;
	margin: 0px 20px;
}

.Previous_organ {
	display: flex;
	justify-content: space-between;
	padding: 0px 12px 29px 20px;
}

.Next_button {
	border: 1px solid rgb(232, 232, 232);
	background-color: rgb(246, 242, 242);
	font-size: 14px;
	border-radius: 3px;
	margin-right: 4px;
}
.Next_button:hover{
	background-color: #edf3fe;
}

.no1_button {
	border: 1px solid rgb(171, 169, 169);
	background-color: #c3c4c7;
	font-size: 14px;
	border-radius: 3px;
	margin-right: 3px;


}



/* sub organization */
.Organization_sub {
	margin: 20px 10px;
}

.btn_organization {
	background-color: #315189;
	font-size: 9px;
	color: white;
	font-weight: 600;
	border: 1px solid blueviolet;


}

.arrowkey {
	padding: 0px 5px;
}













/*  */

.OrganView {
	display: flex;
	font-size: 14px;
	font-weight: 600;
	margin: 15px;

}




.sidebar-content a {
	text-transform:capitalize !important;
}
.hONoP .activeClicked >li {
  color: red !important;
  background-color: #283e64 !important;
  
}

.hONoP .activeClicked span {
  color:white !important;
  
}
.dashboard .cards-container {
    margin: 25px auto;
    display: grid;
    width: 100%;
    grid-template-columns: repeat(3, minmax(300px, 1fr));
    grid-gap: 25px;
    grid-template-rows: 1fr;
}

.dashboard .card-bg {
    border: 1px solid #f2f2f2;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.span-error {

    font-size: 14px;
    color: red;
}

.dashboard .wide {
    grid-column: span 2;
}

.dashboard .c-p {
    cursor: pointer;
}
.center-align{

    display: flex;
    justify-content: center;
}
.assign_addbtn {
    margin-bottom: 5px;
    margin-left: 10px;
    margin-top: 3px;
    font-size: 12px;
    font-weight: 600;
    color: white;
    background-color: #315189;

}

.assign_addbtn:hover {
    color: white;
    background-color: #315189;
}

.dashboard .pane-image {
    min-width: 3rem;
    height: 3rem;
    margin-right: 1rem;
}

.dashboard .card-section {
    padding: 0 1.5rem;
    box-sizing: border-box;
}

.dashboard .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem 0;
    width: 80%;
    margin: 0 auto;
}

.dashboard .footer-link {
    display: flex;
    align-items: center;
    padding: 0;
    color: #333;
}

@media (max-width:1320px) {
    .dashboard .cards-container {
        grid-gap: 15px;
        grid-template-columns: repeat(2, minmax(270px, 1fr));
    }

    .dashboard .wide {
        grid-column: span 1;
    }

    .dashboard .card-section {
        padding: 0 0.5rem;
    }

    .dashboard .table-rem {
        display: none !important;
    }

    .dashboard .footer {
        width: 90%;
    }
}

@media (max-width:920px) {
    .dashboard .cards-container {
        max-width: 500px;
        width: 90%;
        font-size: 0.85rem;
        grid-template-columns: repeat(1, minmax(220px, 100%));
    }

    .dashboard .msg-rem {
        display: none !important;
    }

    .dashboard .p-4 {
        padding: 1rem !important;
    }

    .dashboard .pane-image {
        min-width: 2rem;
        height: 2rem;
        margin-right: 0.5rem;
    }

    .dashboard .footer-rem,
    .dashboard .footer-link {
        display: none !important;
    }

    .dashboard .footer .d-flex {
        margin: 0 auto;
        text-align: center;
    }
}

@media (max-width:375px) {
    .dashboard .h5 {
        font-size: 1rem;
    }

    .dashboard .cards-container {
        margin: 10px 0;
    }
}

/* setting as dhashboard */

/* .fourheading {
    color: white !important;
    background-color: #162031 !important;
} 
.fourheading:hover {
    color: white !important;
    background-color: #283e64 !important;
}  */
/* .fourheading:hover {
    color: white !important;
    background-color: #a8b820 !important;
}  */

/* .hONoP a{
    color: red !important;
} */

.fourheading {
    padding: 0px 8px 0px 0px !important;
    font-size: 14px !important;
}


.input_align {

    width: 100%;
    border-radius: 4px !important;
    border: 1px solid rgb(193, 192, 192);
    height: 34px !important;

}

.input_align1 {

    width: 100%;
    border-radius: 4px !important;
    border: 1px solid rgb(193, 192, 192);
    height: 60px !important;

}

.nav-font {
    font-size: 11px;
    color: white;
    padding: 3px;
    margin-top: 6px;

}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-button {
    background-color: #162031;
    color: #fff;
    border: none;
    padding: 10px 0px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.changepas-center {
    margin-top: 14px;
    display: grid;
    justify-content: center;
}

.dropdown-icon {
    margin-right: 5px;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #fbf8f8;
    width: 130px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    font-size: small !important;
    z-index: 1;

}

.dropdown-contentnav {
    display: none;
    position: absolute;
    background-color: #fbf8f8;
    width: 130px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    font-size: small !important;
    z-index: 1;

}

.dropdown-contentnav a {
    padding: 10px;
    display: block;
    text-decoration: none;
    color: #333;
    transition: background-color 0.2s;

}

.dropdown-contentnav a:hover {
    background-color: #f2f2f2;
}

.dropdown-content a {
    padding: 10px;
    display: block;
    text-decoration: none;
    color: #333;
    transition: background-color 0.2s;

}

.dropdown-content a:hover {
    background-color: #f2f2f2;
}


.dropdown:hover .dropdown-content,
.dropdown:focus-within .dropdown-content {
    display: block;
}

.logonav {
    display: flex;
    border: 1px solid white;
    padding: 4px 0px;
    margin-right: 80px;
}

.logoimgnav {
    margin-Right: 8px;

    height: auto;
    border-Radius: 50%;
    height: 35px;
    width: 35px;
}

/* dashboard as setting page */
.profile-tilte {
    font-size: 24px;
    font-weight: 400;
    background-color: #f2f4f9;
    border-bottom: 2px solid #ddd;
    padding: 15px;

}

/* .border-box {
    border: 2px solid black;
    padding: 20px;
    border-radius: 10px;
} */
.form-group {
    display: flex;
}

.form-textSetting {
    font-size: 13px;
    font-weight: 500;
    display: flex !important;
}

.inputbox-height {

    height: 37px;
}

.required {
    color: red !important;
}

.btn-setting {
    background-color: #315189;
    font-size: 7px;
    color: white;
    font-weight: 600;
    border: 1px solid blueviolet;

    margin-top: -44px;
}

.btn-setting:hover {
    color: white;
}

.logoimg-setting {
    border-Radius: 50%;
    height: 108px;
    width: 123px;
    /* margin-top: -27px; */
}
.Editprofile {
    background-color: #e2e6ee;
    font-size: 16px;
    padding: 12px;
    border-bottom: 2px solid #ddd;
    font-weight: 400;

}

.borderbox {
    border: 2px solid #ddd;
    margin: 10px 3px;
}

.background-color {
    width: 100%;

    background-color: #f2f4f9;
}

/* .button_top{
    margin-top: 20px;
} */
@media (max-width: 1000px) {
    .form-group {
        display: grid;
    }

    .mob-overflow {
        overflow: auto !important;
    }

    .form-textSetting {

        display: grid !important;
    }

    .OrganView {
        display: grid !important;

    }

}

.yellow-status {
    background-color: rgb(208, 208, 16);
    font-size: 12px;
    font-weight: 700;
    color: white;
    padding: 6px 10px;
    margin-left: -5px;
}

.green-status {
    background-color: #82af6f;
    font-size: 12px;
    font-weight: 700;
    color: white;
    padding: 6px 16px;
    margin-left: -4px;
}

.red-status {
    background-color: rgb(198, 77, 77);
    font-size: 12px;
    font-weight: 700;
    color: white;
    padding: 6px 10px;
    margin-left: -6px;
}



/* profile as Organization management */
.Orvalidation_form {
    background-color: #e2e6ee;
    display: flex;
    justify-content: space-between;
    padding: 0px 8px;
}

.Organ_addbtn {
    text-align: end;
    font-size: 12px;
    font-weight: 600;
    color: white;
    margin: 10px;

    background-color: #315189;

}

.Organ_addbtn:hover {
    color: white;
    background-color: #315189;
}

.Orpage_box {
    border: 2px solid #ddd;
    margin: 15px 10px;
    border-radius: 5px;

}

.icon:hover {
    color: red;
}

.sidebar-content a {
    text-transform: capitalize !important;
}

.hONoP .activeClicked>li {
    color: red !important;
    background-color: #283e64 !important;

}

.hONoP .activeClicked span {
    color: white !important;

}

.end-navContent {
    justify-content: flex-end !important;
    margin-right: 33px;
}

/* 
@media (max-width: 530px){
.list_organ {
    font-size: 10px!important;
}
.Organ_addbtn {
    font-size: 8px;
}
} */
.note-style {
    height: 100px !important;
}

/* Add these classes to your CSS file */
.active {
    background-color: green !important;
}

.inactive {
    background-color: red !important;
}

.pending {
    background-color: yellow !important;
}


.myself {
    color: blue;
    cursor: pointer;
}

.myself:hover {
    color: black;
}

/* .req-style {
    padding: 10px 43px;
    font-size: 14px;
    border-radius: 6px;
} */


.custom-dropdown {
    position: relative;
    display:inline-block;
 
    /* margin: 10px; */
}
.custom-124 {
  
    display:flex;
    justify-content: center;
    /* margin: 10px; */
}
.req-style {
    background-color: #007bff;
    color: white;
    padding: 8px 45px;
    border: none;
    cursor: pointer;
    border-radius: 2px;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.dropdown-content label {
    display: block;
    padding: 8px 12px;
    cursor: pointer;
}

.dropdown-content label:hover {
    background-color: #ddd;
}

input[type="checkbox"] {
    margin-right: 8px;
}

.custom-dropdown:hover .dropdown-content {
    display: block;
}
@media (max-width:530px) {


	.Organ_addbtn {
		font-size: 7px;

	}

	.list_organ {
		font-size: 10px !important;

	}

	.Organ_dropdown1 {
		display: grid !important;
		padding: 0px 21px !important;
		margin-top: 19px !important;

	}

	.Organ_dropdown {
		padding-top: 12px !important;

	}

}

/*  */
/* .tabledata{
	margin-top: 10px !important;
} */
.tabledata:hover {
	background-color: rgb(232, 231, 231);
}

.list_organ {
	font-size: 16px;
	font-weight: 400;
	padding: 12px;
	
}

#dropdown {
	width: 62px;
	/* padding: 7px; */
	border: 1px solid #6f6f6f;
	border-radius: 4px;
	font-size: 14px;
	height: 31px;
	padding: 0px 7px;
	color: rgb(171, 169, 169);
}

.Organ_dropdown {
	display: flex;

}

.search_width {
	width: 167px !important;
	
}

.recordpage {
	font-size: 13px;
	padding: 0px 5px;
	
}

.recordpage {
	font-size: 14px;
	padding: 4px 5px;

}

.Organ_dropdown1 {
	display: flex;
	justify-content: space-between;
	padding: 0px 21px;
	margin-top: 19px;
}





.Organ_dropdown2 {
	display: flex;
	margin-top: 19px;
}

/* table */
table {
	border-collapse: collapse;
	width: 100%;
}

td {
	border-bottom: 1px solid #ddd;
	padding: 16px;
	text-align: left;
	font-size: 13px;
}

.color {
	background-color: #8f9fba;
	padding: 8px;
	font-size: 13px;
	color: white;
}

.table-colorOrgan {
	border: 1px solid #ddd;
	margin: 20px;
}

.tr_padd {
	padding: 10px;
}

 
.Active_label {
	background-color: #82af6f;
	font-size: 12px;
	font-weight: 700;
	color: white;
	padding: 6px 16px;
	margin-left: 2px;
}

.padding_sort {
	margin: 6px;
}

.dropdown_alert {
	border: 1px solid rgb(187, 186, 186);
	padding: 1px 8px;
	background-color: #dddde0;
	color: #8f9fba;
	margin: 0px 20px;
}

.Previous_organ {
	display: flex;
	justify-content: space-between;
	padding: 0px 12px 29px 20px;
}

.Next_button {
	border: 1px solid rgb(232, 232, 232);
	background-color: rgb(246, 242, 242);
	font-size: 14px;
	border-radius: 3px;
	margin-right: 4px;
}
.Next_button:hover{
	background-color: #edf3fe;
}

.no1_button {
	border: 1px solid rgb(171, 169, 169);
	background-color: #c3c4c7;
	font-size: 14px;
	border-radius: 3px;
	margin-right: 3px;


}



/* sub organization */
.Organization_sub {
	margin: 20px 10px;
}

.btn_organization {
	background-color: #315189;
	font-size: 9px;
	color: white;
	font-weight: 600;
	border: 1px solid blueviolet;


}

.arrowkey {
	padding: 0px 5px;
}













/*  */

.OrganView {
	display: flex;
	font-size: 14px;
	font-weight: 600;
	margin: 15px;

}




.sidebar-content a {
	text-transform:capitalize !important;
}
.hONoP .activeClicked >li {
  color: red !important;
  background-color: #283e64 !important;
  
}

.hONoP .activeClicked span {
  color:white !important;
  
}
