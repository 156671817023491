@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&display=swap');

* {
  font-family: "Montserrat", sans-serif;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #ccc;
}

.sidebar-content a {
  text-transform: capitalize !important;
}

.hONoP .activeClicked>li {
  color: red !important;
  background-color: #283e64 !important;

}

.color {
  background-color: #8F9FBA !important;
  padding: 8px !important;
  font-size: 13px !important;
  color: white !important;
  width: 50px;
}

td {
  border-bottom: 1px solid #ddd !important;
  padding: 12px !important;
  text-align: left;
  font-size: 12px !important;
}

.save-btn {
  text-align: end;
  font-size: 12px;
  font-weight: 600;
  color: white;
  background-color: #315189;
  margin-left: 10px;
  /* margin-top: 10px; */


}

.interview_class {
  display: block;
  width: 380px;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out;
  box-shadow: .15s ease-in-out;
  font-size: 13px !important;
}





.save-btn:hover {
  color: white;
  background-color: #315189;
}

@media (max-width:530px) {

  .save-btn {

    margin-top: 10px !important;


  }
}

.save-row {
  padding: 0px 21px;
  margin-top: 19px;
  display: flex;
  align-items: center;

}

.spinner-style {
  /* height: 117%; */
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed !important;
  top: 50%;
  height: 0px !important;
}

.spinner-styletop {
  /* background-color: burlywood; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 0px !important; */
  /* top: 50%; */
}

.hONoP .activeClicked span {
  color: white !important;

}

.imkBTA {
  background: #162031;
  color: #fff;
  height: 100%;
  width: 210px !important;
  min-width: 210px !important;
  text-align: left;
  -webkit-transition: width, left, right, 0.3s;
  transition: width, left, right, 0.3s;
  position: relative;
  z-index: 1009;
}


.kgVgLX {

  background: #162031 !important;
  color: #fff !important;
  height: 100%;
  width: 210px !important;
  min-width: 210px !important;
  text-align: left;
  -webkit-transition: width, left, right, 0.3s;
  transition: width, left, right, 0.3s;
  position: relative;
  z-index: 1009;


}

.eDStxa .side-icon {
  margin-right: 0px !important;
  width: 30px;
}

.imkBTA.toggled {
  width: 80px !important;
  min-width: 80px !important;
}

.gmeTLe .item-content {


  /* text-overflow: ellipsis; */
  /* white-space: nowrap; */
  text-overflow: initial !important;
  white-space: initial !important;
  font-size: 13px;
}

.eDStxa .item-content {
  text-overflow: initial !important;
  white-space: initial !important;
  font-size: 13px;

}

.eDStxa {
  padding: 8px 1px 8px 3px !important;
}


.gmeTLe {
  padding: 8px 1px 8px 3px !important;
}

.bwesBh .activeClicked>li {
  background: #29374f !important;
  color: #aeb2b7 !important;
}

.ktshyD .activeClicked>li {

  background: #29374f !important;
  color: #aeb2b7 !important;

}

.activeClicked.gmeTLe .side-icon {

  color: blue;

}

.eDStxa {

  color: #aeb2b7 !important;

}

.bwesBh .activeClicked>span {
  background: #29374f !important;
  color: #aeb2b7 !important;
}

.hQSxZz .activeClicked.side-icon {


  color: aqua !important;
}

.gmeTLe .side-icon {
  margin-right: 0px !important;
  width: 30px;
}

.hQSxZz {
  list-style: none;
  margin: 10px 15px;
  color: #aeb2b7 !important;
}

.hQSxZz:hover {
  list-style: none;
  margin: 10px 15px;
  background: #29374f !important;
  color: #aeb2b7 !important;
}

.fa-cogs:before {
  content: "\f085";
  /* color: #FF6C60; */
}

.fRhOkl {

  padding-top: 0px;
}

.fa-envelope:before:hover {
  content: "\f0e0";
  color: #FF6C60 !important;

}

.gmeTLe .side-icon :hover {
  color: #FF6C60 !important;
}

.spinner-style {
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}


.logoimgnav {
  margin-Right: 8px;
  height: auto;
  border-Radius: 50%;
  height: 22px;
  width: 22px;


}

.logonav {
  display: flex;
  border: 1px solid white;
  padding: 4px 0px;
  margin-right: 80px;
}


.nav-font {
  font-size: 11px;
  color: white;
  padding: 3px;
  margin-top: 6px;

}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-button {
  background-color: #162031;
  color: #fff;
  border: none;
  padding: 10px 0px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.changepas-center {
  margin-top: 14px;
  display: grid;
  justify-content: center;
}

.dropdown-icon {
  margin-right: 5px;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #fbf8f8;
  width: 130px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  font-size: small !important;
  z-index: 1;

}

.dropdown-contentnav {
  display: none;
  position: absolute;
  background-color: #fbf8f8;
  width: 130px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  font-size: small !important;
  z-index: 1;

}

.dropdown-contentnav a {
  padding: 10px;
  display: block;
  text-decoration: none;
  color: #333;
  transition: background-color 0.2s;

}

.dropdown-contentnav a:hover {
  background-color: #f2f2f2;
}

.dropdown-content a {
  padding: 10px;
  display: block;
  text-decoration: none;
  color: #333;
  transition: background-color 0.2s;

}

.dropdown-content a:hover {
  background-color: #f2f2f2;
}


.dropdown:hover .dropdown-content,
.dropdown:focus-within .dropdown-content {
  display: block;
}

.not-found-container {
  text-align: center;
  padding: 50px;
  font-family: 'Arial', sans-serif;
}

h1 {
  font-size: 3em;
  margin-bottom: 20px;
  color: #ff5722;
  /* Orange color */
}

p {
  font-size: 1.5em;
  color: #333;
}

.not-found-image {
  max-width: 100%;
  margin-top: 20px;
}

.jAaeFT {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.iCVXfv {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.react-datepicker-wrapper {
  display: flex !important;


}

.clean-text {
  background-color: rgb(86, 187, 226);
  border: 1px solid rgb(73, 183, 226);
  border-radius: 4px;
  font-size: 12px;
  padding: 6px 21px;
  font-weight: 600;
  color: white;
}


.email_content_text {

  height :250px;
}