.sidebar-content a {
  text-transform: capitalize !important;
}

.hONoP .activeClicked li {
  color: red !important;
  background-color: #283e64 !important;

}

.hONoP .activeClicked span {
  color: rgb(252, 252, 252) !important;

}


.left-logo {

  height: auto;
  width: 62px;
}

/* styles for sidebar icons */