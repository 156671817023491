.dashboard .cards-container {
    margin: 25px auto;
    display: grid;
    width: 100%;
    grid-template-columns: repeat(3, minmax(300px, 1fr));
    grid-gap: 25px;
    grid-template-rows: 1fr;
}

.dashboard .card-bg {
    border: 1px solid #f2f2f2;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.span-error {

    font-size: 14px;
    color: red;
}

.dashboard .wide {
    grid-column: span 2;
}

.dashboard .c-p {
    cursor: pointer;
}
.center-align{

    display: flex;
    justify-content: center;
}
.assign_addbtn {
    margin-bottom: 5px;
    margin-left: 10px;
    margin-top: 3px;
    font-size: 12px;
    font-weight: 600;
    color: white;
    background-color: #315189;

}

.assign_addbtn:hover {
    color: white;
    background-color: #315189;
}

.dashboard .pane-image {
    min-width: 3rem;
    height: 3rem;
    margin-right: 1rem;
}

.dashboard .card-section {
    padding: 0 1.5rem;
    box-sizing: border-box;
}

.dashboard .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem 0;
    width: 80%;
    margin: 0 auto;
}

.dashboard .footer-link {
    display: flex;
    align-items: center;
    padding: 0;
    color: #333;
}

@media (max-width:1320px) {
    .dashboard .cards-container {
        grid-gap: 15px;
        grid-template-columns: repeat(2, minmax(270px, 1fr));
    }

    .dashboard .wide {
        grid-column: span 1;
    }

    .dashboard .card-section {
        padding: 0 0.5rem;
    }

    .dashboard .table-rem {
        display: none !important;
    }

    .dashboard .footer {
        width: 90%;
    }
}

@media (max-width:920px) {
    .dashboard .cards-container {
        max-width: 500px;
        width: 90%;
        font-size: 0.85rem;
        grid-template-columns: repeat(1, minmax(220px, 100%));
    }

    .dashboard .msg-rem {
        display: none !important;
    }

    .dashboard .p-4 {
        padding: 1rem !important;
    }

    .dashboard .pane-image {
        min-width: 2rem;
        height: 2rem;
        margin-right: 0.5rem;
    }

    .dashboard .footer-rem,
    .dashboard .footer-link {
        display: none !important;
    }

    .dashboard .footer .d-flex {
        margin: 0 auto;
        text-align: center;
    }
}

@media (max-width:375px) {
    .dashboard .h5 {
        font-size: 1rem;
    }

    .dashboard .cards-container {
        margin: 10px 0;
    }
}

/* setting as dhashboard */

/* .fourheading {
    color: white !important;
    background-color: #162031 !important;
} 
.fourheading:hover {
    color: white !important;
    background-color: #283e64 !important;
}  */
/* .fourheading:hover {
    color: white !important;
    background-color: #a8b820 !important;
}  */

/* .hONoP a{
    color: red !important;
} */

.fourheading {
    padding: 0px 8px 0px 0px !important;
    font-size: 14px !important;
}


.input_align {

    width: 100%;
    border-radius: 4px !important;
    border: 1px solid rgb(193, 192, 192);
    height: 34px !important;

}

.input_align1 {

    width: 100%;
    border-radius: 4px !important;
    border: 1px solid rgb(193, 192, 192);
    height: 60px !important;

}

.nav-font {
    font-size: 11px;
    color: white;
    padding: 3px;
    margin-top: 6px;

}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-button {
    background-color: #162031;
    color: #fff;
    border: none;
    padding: 10px 0px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.changepas-center {
    margin-top: 14px;
    display: grid;
    justify-content: center;
}

.dropdown-icon {
    margin-right: 5px;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #fbf8f8;
    width: 130px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    font-size: small !important;
    z-index: 1;

}

.dropdown-contentnav {
    display: none;
    position: absolute;
    background-color: #fbf8f8;
    width: 130px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    font-size: small !important;
    z-index: 1;

}

.dropdown-contentnav a {
    padding: 10px;
    display: block;
    text-decoration: none;
    color: #333;
    transition: background-color 0.2s;

}

.dropdown-contentnav a:hover {
    background-color: #f2f2f2;
}

.dropdown-content a {
    padding: 10px;
    display: block;
    text-decoration: none;
    color: #333;
    transition: background-color 0.2s;

}

.dropdown-content a:hover {
    background-color: #f2f2f2;
}


.dropdown:hover .dropdown-content,
.dropdown:focus-within .dropdown-content {
    display: block;
}

.logonav {
    display: flex;
    border: 1px solid white;
    padding: 4px 0px;
    margin-right: 80px;
}

.logoimgnav {
    margin-Right: 8px;

    height: auto;
    border-Radius: 50%;
    height: 35px;
    width: 35px;
}

/* dashboard as setting page */
.profile-tilte {
    font-size: 24px;
    font-weight: 400;
    background-color: #f2f4f9;
    border-bottom: 2px solid #ddd;
    padding: 15px;

}

/* .border-box {
    border: 2px solid black;
    padding: 20px;
    border-radius: 10px;
} */
.form-group {
    display: flex;
}

.form-textSetting {
    font-size: 13px;
    font-weight: 500;
    display: flex !important;
}

.inputbox-height {

    height: 37px;
}

.required {
    color: red !important;
}

.btn-setting {
    background-color: #315189;
    font-size: 7px;
    color: white;
    font-weight: 600;
    border: 1px solid blueviolet;

    margin-top: -44px;
}

.btn-setting:hover {
    color: white;
}

.logoimg-setting {
    border-Radius: 50%;
    height: 108px;
    width: 123px;
    /* margin-top: -27px; */
}
.Editprofile {
    background-color: #e2e6ee;
    font-size: 16px;
    padding: 12px;
    border-bottom: 2px solid #ddd;
    font-weight: 400;

}

.borderbox {
    border: 2px solid #ddd;
    margin: 10px 3px;
}

.background-color {
    width: 100%;

    background-color: #f2f4f9;
}

/* .button_top{
    margin-top: 20px;
} */
@media (max-width: 1000px) {
    .form-group {
        display: grid;
    }

    .mob-overflow {
        overflow: auto !important;
    }

    .form-textSetting {

        display: grid !important;
    }

    .OrganView {
        display: grid !important;

    }

}

.yellow-status {
    background-color: rgb(208, 208, 16);
    font-size: 12px;
    font-weight: 700;
    color: white;
    padding: 6px 10px;
    margin-left: -5px;
}

.green-status {
    background-color: #82af6f;
    font-size: 12px;
    font-weight: 700;
    color: white;
    padding: 6px 16px;
    margin-left: -4px;
}

.red-status {
    background-color: rgb(198, 77, 77);
    font-size: 12px;
    font-weight: 700;
    color: white;
    padding: 6px 10px;
    margin-left: -6px;
}



/* profile as Organization management */
.Orvalidation_form {
    background-color: #e2e6ee;
    display: flex;
    justify-content: space-between;
    padding: 0px 8px;
}

.Organ_addbtn {
    text-align: end;
    font-size: 12px;
    font-weight: 600;
    color: white;
    margin: 10px;

    background-color: #315189;

}

.Organ_addbtn:hover {
    color: white;
    background-color: #315189;
}

.Orpage_box {
    border: 2px solid #ddd;
    margin: 15px 10px;
    border-radius: 5px;

}

.icon:hover {
    color: red;
}

.sidebar-content a {
    text-transform: capitalize !important;
}

.hONoP .activeClicked>li {
    color: red !important;
    background-color: #283e64 !important;

}

.hONoP .activeClicked span {
    color: white !important;

}

.end-navContent {
    justify-content: flex-end !important;
    margin-right: 33px;
}

/* 
@media (max-width: 530px){
.list_organ {
    font-size: 10px!important;
}
.Organ_addbtn {
    font-size: 8px;
}
} */
.note-style {
    height: 100px !important;
}

/* Add these classes to your CSS file */
.active {
    background-color: green !important;
}

.inactive {
    background-color: red !important;
}

.pending {
    background-color: yellow !important;
}


.myself {
    color: blue;
    cursor: pointer;
}

.myself:hover {
    color: black;
}

/* .req-style {
    padding: 10px 43px;
    font-size: 14px;
    border-radius: 6px;
} */


.custom-dropdown {
    position: relative;
    display:inline-block;
 
    /* margin: 10px; */
}
.custom-124 {
  
    display:flex;
    justify-content: center;
    /* margin: 10px; */
}
.req-style {
    background-color: #007bff;
    color: white;
    padding: 8px 45px;
    border: none;
    cursor: pointer;
    border-radius: 2px;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.dropdown-content label {
    display: block;
    padding: 8px 12px;
    cursor: pointer;
}

.dropdown-content label:hover {
    background-color: #ddd;
}

input[type="checkbox"] {
    margin-right: 8px;
}

.custom-dropdown:hover .dropdown-content {
    display: block;
}