@media (max-width:530px) {


	.Organ_addbtn {
		font-size: 7px;

	}

	.list_organ {
		font-size: 10px !important;

	}

	.Organ_dropdown1 {
		display: grid !important;
		padding: 0px 21px !important;
		margin-top: 19px !important;

	}

	.Organ_dropdown {
		padding-top: 12px !important;

	}

	.close-icon-container {
		position: relative;
		/* top: 74%; */
		/* right: 16px; */
		padding: 5px;
		cursor: pointer;
		top: -150px !important;
		left: 30px;
		text-align: end;
		/* display: flex; */
		font-size: 19px;
	}

}


.modal-header {
	background-color: #3d609a !important;
	color: white !important;
	font-size: 16px;
}

.modal-title {
	margin-bottom: 0;
	line-height: var(--bs-modal-title-line-height);
	font-size: 17px;
}

/*  */
/* .tabledata{
	margin-top: 10px !important;
} */
.tabledata:hover {
	background-color: rgb(232, 231, 231);
}

.list_organ {
	font-size: 16px;
	font-weight: 400;
	padding: 12px;

}

#dropdown {
	width: 62px;
	/* padding: 7px; */
	border: 1px solid #6f6f6f;
	border-radius: 4px;
	font-size: 14px;
	height: 31px;
	padding: 0px 7px;
	color: rgb(171, 169, 169);
}

.Organ_dropdown {
	display: flex;

}

.search_width {
	width: 167px !important;

}

.recordpage {
	font-size: 13px;
	padding: 0px 5px;

}

.recordpage {
	font-size: 14px;
	padding: 4px 5px;

}

.Organ_dropdown1 {
	display: flex;
	justify-content: space-between;
	padding: 0px 21px;
	margin-top: 19px;
}





.Organ_dropdown2 {
	display: flex;
	margin-top: 19px;
}

/* table */
table {
	border-collapse: collapse;
	width: 100%;
}

td {
	border-bottom: 1px solid #ddd;
	padding: 16px;
	text-align: left;
	font-size: 13px;
}

.color {
	background-color: #8f9fba;
	padding: 8px;
	font-size: 13px;
	color: white;
}

.table-colorOrgan {
	border: 1px solid #ddd;
	margin: 20px;
}

.tr_padd {
	padding: 10px;
}


.Active_label {
	background-color: #82af6f;
	font-size: 12px;
	font-weight: 700;
	color: white;
	padding: 6px 16px;
	margin-left: 2px;
}

.padding_sort {
	margin: 6px;
}

.dropdown_alert {
	border: 1px solid rgb(187, 186, 186);
	padding: 1px 8px;
	background-color: #dddde0;
	color: #8f9fba;
	margin: 0px 20px;
}

.Previous_organ {
	display: flex;
	justify-content: space-between;
	padding: 0px 12px 29px 20px;
}
.Previous_save {
	display: flex;
	justify-content: flex-end;
	width: 80%;
	
}

.Next_button {
	border: 1px solid rgb(232, 232, 232);
	background-color: rgb(246, 242, 242);
	font-size: 14px;
	border-radius: 3px;
	margin-right: 4px;
}

.Next_button:hover {
	background-color: #edf3fe;
}

.no1_button_selected {
	border: 1px solid rgb(171, 169, 169);
	background-color: #c3c4c7;
	font-size: 14px;
	border-radius: 3px;
	margin-right: 3px;


}
.no1_button_unselected {
	border: 1px solid rgb(104 96 96);
	background-color: #ffffff;
	font-size: 14px;
	border-radius: 3px;
	margin-right: 3px;


}


/* sub organization */
.Organization_sub {
	margin: 20px 10px;
}

.btn_organization {
	background-color: #315189;
	font-size: 9px;
	color: white;
	font-weight: 600;
	border: 1px solid blueviolet;


}

.arrowkey {
	padding: 0px 5px;
}













/*  */

.OrganView {
	display: flex;
	font-size: 14px;
	font-weight: 600;
	margin: 15px;

}




.sidebar-content a {
	text-transform: capitalize !important;
}

.hONoP .activeClicked>li {
	color: red !important;
	background-color: #283e64 !important;

}

.hONoP .activeClicked span {
	color: white !important;

}


.reqnote_time {
	font-weight: 300;
	text-align: end;
	display: flex;
	justify-content: end;
	align-items: end;
	margin-top: 7px;
}

.req_notesview {
	/* display: flex; */
	font-size: 14px;
	font-weight: 600;
	margin: 27px;
	border: 0.5px solid #bbb6b6;
	padding: 10px;
	font-style: italic;
}

.close-icon-container {
	position: relative;
	/* top: 74%; */
	/* right: 16px; */
	padding: 5px;
	cursor: pointer;
	top: -80px;
	left: 22px;
	text-align: end;
	/* display: flex; */
	font-size: 19px;
	margin-bottom: -21px;
}


.cursor_notallowed {
	cursor: not-allowed !important;
}

.css-1jqq78o-placeholder {

	font-size: 13px !important;
}

.notes_scroll {
	height: 340px;
	overflow-y: scroll;
}
