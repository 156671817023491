/* In your CSS file (e.g., App.css) */
/* @import '~font-awesome/css/font-awesome.min.css'; */

.icon_check {
	color: #00BBB3;
	font-size: medium;
}

.img_logoNew {
	display: flex;
	justify-content: center !important;
	align-items: center !important;
}

.altimaximg {
	background-color: #E8F1F6;
	width: 100%;
}

.logoimg {
	margin: 0px auto;
	display: flex;
	justify-content: center;
	align-items: center;

}




/* .logo-text{ */

.input-color {
	color: #393939;
}

.email-text {
	font-size: 18px;
	margin-bottom: 5px 0;
}

.password-text {
	font-size: 18px;
}

.login {
	background-color: #FFFFFF;
	margin: 35px 0;
	padding: 20px 90px;
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.logins {
	font-family: 'ralewaymedium';
	text-align: center;
	color: #00BBB3;
	font-weight: 700 !important;
	margin-bottom: 30px;
	font-size: 26px;
	margin-top: 30px;
}

.form-group {
	margin-bottom: 15px;
	display: block !important;
}

label {
	display: inline-block;
	max-width: 100%;
	margin-bottom: 5px;
	font-weight: 600;
}

.form-control {
	display: block;
	width: 100%;
	padding: 0.375rem 0.75rem;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #212529;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #ced4da;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	border-radius: 0.25rem;
	transition: border-color .15s ease-in-out;
	box-shadow: .15s ease-in-out;
	font-size: 13px !important;
}



.box {
	height: 48px !important;
	background-color: #E8F1F6;
	/* font-family: 'ralewaysemibold'; */
	font-size: 14px !important;
	border: none;
}

.lab {
	display: inline-block;
	max-width: 100%;
	margin-bottom: 5px;
	font-weight: 600;
}

.forgetpass a {
	font-family: 'ralewaybold';
	color: #00bbb4 !important;
	text-decoration: none;
	font-size: 16px;
}

.submit {
	background-color: #00bbb4 !important;
	border: 0 none;
	color: #fff !important;
	padding: 13px 50px !important;
	text-transform: uppercase !important;
	font-family: 'ralewaybold';
	font-size: 16px !important;
	margin-bottom: 25px !important;
	cursor: pointer;
}

.btn {
	display: inline-block;
	font-weight: 400;
	line-height: 1.5;
	color: #212529;
	text-align: center;
	text-decoration: none;
	vertical-align: middle;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
	background-color: transparent;
	border: 1px solid transparent;
	padding: 0.375rem 0.75rem;
	font-size: 1rem;
	border-radius: 0.25rem;
	transition: color .15s ease-in-out;
	background-color: .15s ease-in-out;
	border-color: .15s ease-in-out;
	box-shadow: .15s ease-in-out;
}


.details {
	line-height: 2.5;

}

.logininfo {
	margin: 110px 0;
	padding: 38px;
	font-family: 'ralewaymedium';
	color: #000002 !important;
}

.icon-tick {
	color: #00bbb4;
}

.icon-space {
	padding-left: 6px;
	font-weight: 500;
	font-size: 15px;
}

.icon-space1 {
	margin-left: 20px;
	font-weight: 500;
	font-size: 15px;
}

.footer {
	background-color: #00BBB4;
	display: flex;
	align-items: center;
	justify-content: center;
}

.list-inline {
	display: flex;
	padding-left: 0;
	list-style: none;
}

.socialicon {
	margin-top: 7px;
}

.footer-img {
	margin: 15px;

}

.footer-para {
	margin: 33px;
	color: #fff;
	text-align: center;

	font-family: 'ralewaysemibold';
	font-size: 15px;
}

.socialicon {
	margin-top: 28px;
	margin-left: 32px;
}

.socialicon li {
	list-style: none;
	display: inline-block;
	margin-right: 15px;
}


.socialicon a {
	display: block;
	width: 33px;
	height: 33px;
	text-align: center;
	line-height: 33px !important;
	border-radius: 50%;
	background-color: white;
	text-decoration: none;
	font-size: 19px;
	color: #00BBB4;
}

.logomm {
	width: 100px;

}

.sizeof_heading {
	font-size: 14px !important;
	font-weight: 500 !important;
}

.Inactive_label {
	background-color: red;
	font-size: 12px;
	font-weight: 700;
	color: white;
	padding: 7px 11px;
	margin-left: 2px;
}


/* Admin page 2 settings */


.sidebar-content a {
	text-transform: capitalize !important;
}

.hONoP .activeClicked>li {
	color: red !important;
	background-color: #283e64 !important;

}

.hONoP .activeClicked span {
	color: white !important;

}

.checkbox_visa {
	display: flex;
}

.form-check-label {
	margin-bottom: 0;
	padding: 2px 14px;
}

.checkmtop {
	margin-left: 15px;
}

.logoimgnav2 {
	margin-Right: 8px;
	height: auto;
	border-Radius: 50%;
	height: 22px;
	width: 22px;


}
.logo_notes {

	height: auto;
	width: 17px;


}
.history_dropdown {
	margin-top: 7px;
}

.text-candidate {
	font-size: 14px;
	font-weight: 700;
}

.candidate-row {
	padding: 0px 0px 0px 21px;
}

.Candidate-dropdown {

	margin: 20px 2px;
}

.Account-report {
	text-align: center;
}

.nav-item.active {
	background-color: red !important;
}

.nav-link.active {
	color: white;
}

@media (max-width:1000px) {
	.candidate-row {
		padding: 0px 15px !important;
	}


}

@media (max-width:920px) {
	.mob-inputreport {
		padding: 8px 0px !important;
	}

	.mob-reportfull {
		display: grid !important;
	}
}


/* @media (min-width:1000px) {
	.Reqhide-desktop{
		display: block;
	}

} */
/* @media (max-width:1600px) {
	.Reqhide-mobview{
		display: block;
	}

} */

.bg-color {
	background-color: #e2e6ee !important;
	padding: 10px 0px 0px 5px;

}

.color-report {
	background-color: white !important;
}

.reqcontent-centre {
	display: flex !important;
	justify-content: center !important;
	align-items: center !important;
}

.button-noneSelected {
	border: 1px solid grey;
	font-size: 13px;
	padding: 5px;
}

.text-assign {
	font-size: 15px;
	font-weight: 500;
	padding: 0px 15px 0px 0px;
}

.text-assign11 {
	font-size: 15px;
	font-weight: 500;
	padding: 7px 15px 0px 0px;
}

/* report page styles */

.report-date {
	display: flex;
	padding: 16px 24px;
}

.report-dateheading {
	margin-right: 10px;
	font-size: 14px;
	font-weight: 600;
	/* margin-left: 10px; */

}

.report-inputdate {
	padding: 5px 8px;
	font-size: 13px;
	margin-right: 20px;
}

.report-search {
	background-color: rgb(86, 187, 226);
	border: 1px solid rgb(73, 183, 226);
	border-radius: 4px;
	font-size: 12px;
	padding: 6px 7px;
	font-weight: 600;
	color: white;
}

.report-color1 {
	text-decoration: none;
	color: white;
	font-weight: 600;
	font-size: 15px;
	background-color: #5b7399;
	padding: 8px 4px 3px 4px;
	margin-right: 6px;
	/* border-radius: 4px; */
}

.report-color1:hover {
	text-decoration: none;
	color: black;
	font-weight: 600;
	font-size: 15px;
	background-color: white;
	padding: 8px 4px 3px 4px;
	margin-right: 6px;
	/* border-radius: 4px; */
}
.report-color1_hover {
	text-decoration: none;
	color: black;
	font-weight: 600;
	font-size: 15px;
	background-color: white;
	padding: 8px 4px 3px 4px;
	margin-right: 6px;
	/* border-radius: 4px; */
}

.report-color1_hover:hover {
	text-decoration: none;
	color: black;
	font-weight: 600;
	font-size: 15px;
	background-color: white;
	padding: 8px 4px 3px 4px;
	margin-right: 6px;
	/* border-radius: 4px; */
}


.justify-content-end1 {
	display: flex !important;
	justify-content: flex-end !important;

}

.changepas-center {
	margin-top: 14px;
	display: grid;
	justify-content: center;
}

/* .text-dec{
	text-decoration: none;
} */
a {
	text-decoration: none;
}

/* styles for icons */

/* .fa-user-cog:before {
    content: "red";
	color: red;
} */