@media (max-width:530px) {


	.Organ_addbtn {
		font-size: 7px;

	}

	.list_organ {
		font-size: 10px !important;

	}

	.Organ_dropdown1 {
		display: grid !important;
		padding: 0px 21px !important;
		margin-top: 19px !important;

	}

	.Organ_dropdown {
		padding-top: 12px !important;

	}

}

/*  */
/* .tabledata{
	margin-top: 10px !important;
} */
.tabledata:hover {
	background-color: rgb(232, 231, 231);
}

.list_organ {
	font-size: 16px;
	font-weight: 400;
	padding: 12px;
	
}

#dropdown {
	width: 62px;
	/* padding: 7px; */
	border: 1px solid #6f6f6f;
	border-radius: 4px;
	font-size: 14px;
	height: 31px;
	padding: 0px 7px;
	color: rgb(171, 169, 169);
}

.Organ_dropdown {
	display: flex;

}

.search_width {
	width: 167px !important;
	
}

.recordpage {
	font-size: 13px;
	padding: 0px 5px;
	
}

.recordpage {
	font-size: 14px;
	padding: 4px 5px;

}

.Organ_dropdown1 {
	display: flex;
	justify-content: space-between;
	padding: 0px 21px;
	margin-top: 19px;
}





.Organ_dropdown2 {
	display: flex;
	margin-top: 19px;
}

/* table */
table {
	border-collapse: collapse;
	width: 100%;
}

td {
	border-bottom: 1px solid #ddd;
	padding: 16px;
	text-align: left;
	font-size: 13px;
}

.color {
	background-color: #8f9fba;
	padding: 8px;
	font-size: 13px;
	color: white;
}

.table-colorOrgan {
	border: 1px solid #ddd;
	margin: 20px;
}

.tr_padd {
	padding: 10px;
}

 
.Active_label {
	background-color: #82af6f;
	font-size: 12px;
	font-weight: 700;
	color: white;
	padding: 6px 16px;
	margin-left: 2px;
}

.padding_sort {
	margin: 6px;
}

.dropdown_alert {
	border: 1px solid rgb(187, 186, 186);
	padding: 1px 8px;
	background-color: #dddde0;
	color: #8f9fba;
	margin: 0px 20px;
}

.Previous_organ {
	display: flex;
	justify-content: space-between;
	padding: 0px 12px 29px 20px;
}

.Next_button {
	border: 1px solid rgb(232, 232, 232);
	background-color: rgb(246, 242, 242);
	font-size: 14px;
	border-radius: 3px;
	margin-right: 4px;
}
.Next_button:hover{
	background-color: #edf3fe;
}

.no1_button {
	border: 1px solid rgb(171, 169, 169);
	background-color: #c3c4c7;
	font-size: 14px;
	border-radius: 3px;
	margin-right: 3px;


}



/* sub organization */
.Organization_sub {
	margin: 20px 10px;
}

.btn_organization {
	background-color: #315189;
	font-size: 9px;
	color: white;
	font-weight: 600;
	border: 1px solid blueviolet;


}

.arrowkey {
	padding: 0px 5px;
}













/*  */

.OrganView {
	display: flex;
	font-size: 14px;
	font-weight: 600;
	margin: 15px;

}




.sidebar-content a {
	text-transform:capitalize !important;
}
.hONoP .activeClicked >li {
  color: red !important;
  background-color: #283e64 !important;
  
}

.hONoP .activeClicked span {
  color:white !important;
  
}